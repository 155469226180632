.Clock {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
}

.ClockInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  border: 1vh cyan solid;
  border-radius: 100%;
  font-size: 10vh;
  width: 50vh;
  height: 50vh;
}

.ClockInner.now {
  border-color: gold;
}

@media (orientation: portrait) {
  .ClockInner {
    border-width: 1vw;
    font-size: 10vw;
    width: 50vw;
    height: 50vw;
  }
}
