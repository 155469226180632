.App {
  min-height: 100%;
  padding: 1em;

  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

h1 {
  outline: none;
}

.answer {
  font-size: 2em;
  margin: 0;
}
.answer.now {
  font-size: 2.5em;
}

.time-until {
  margin: 0;
}

.now {
  color: gold;
}

footer ul {
  display: flex;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}
footer li {
  margin: 0 0.25em;
}

.cookie {
  font-size: 0.825rem;
}

.clear {
  border-bottom: 0;
}

.contribute {
  font-size: 0.825em;
  margin-top: 0.5em;
  width: 100%;
}
.contribute a {
  border-bottom-color: transparent;
}

.copyright {
  font-size: 0.825rem;
}

.clear-cookie {
  font-size: 0.825rem;
}
